let documentBody = $('body');
let navigation = null;
let navigationWidth = 0;

let checkMobileNav = function () {
    if (Math.round(navigation.width()) < Math.round(navigationWidth)) {
        if (!documentBody.hasClass('mobile-menu')) {
            documentBody.addClass('mobile-menu');
        }
    } else {
        if (documentBody.hasClass('mobile-menu')) {
            documentBody.removeClass('mobile-menu');
        }
    }
};

export function overrideCheckFunction(overrideFunc) {
    checkMobileNav = overrideFunc;
    checkMobileNav();
}

export function initMobileNav() {
    $(function () {
        navigation = $('header nav');
    });

    $(window).on('resize', function () {
        if (navigation != null && navigation.length) {
            checkMobileNav();
        }
    });

    window.onload = function () {
        navigation = $('header nav');
        if (navigation.length) {
            navigation.find('.level-1 > li').each(function () {
                navigationWidth += $(this).outerWidth();
            });
            checkMobileNav();
        }
    };
}